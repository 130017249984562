import React, { useEffect, useState } from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Row, Col, Image, Button } from "react-bootstrap"
import BlogDefaultImage from "../images/blog-defealt.png"
import CartIcon from "../components/cart/cart-icon-right"
import { Link, graphql } from "gatsby"
import { Helmet } from 'react-helmet';
import ADD_TO_CART from "../mutations/add-to-cart"
import { useQuery, useMutation } from "@apollo/client";
import { checkStartUrl, isSpecial, isUserLoggedIn, toLogin } from "../utils/functions"
import GET_CART from '../queries/get-cart'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ProductTypes } from "../consts"
import 'react-toastify/dist/ReactToastify.css';

const Shop = ({ data }) => {
  const { t } = useTranslation();
  
  const notify = () => toast.success(t('itemAdded'));
  const blogData = data.allWpSimpleProduct.nodes;
  const [productItem, setProductItem] = useState({
    productId: "",
    quantity: ""
  });
  const [monthPrice, setMonthPrice] = useState(true);
  const [isLoading, setLoading] = useState(false)
  const [totalQty, setTotalQty] = useState(null);
  const yearProductList = []
  const monthProductList = []
  const otherProductList = []
  const productCodes = ProductTypes.map((type) => type.product_code)
  const isSpecialAccount = isSpecial()
  blogData?.forEach((p) => {
    const isInSpecial = p.productCategories && p.productCategories.nodes.some((category) => category.name === "Special")
    if(!isSpecialAccount && isInSpecial){
      return
    }else if(isSpecialAccount && !isInSpecial){
      return
    }
    if (p.attributes?.nodes?.[0]?.name === "product_code" && productCodes.includes(p.attributes?.nodes?.[0]?.options?.[0])) {
      if (p.expiresIn === "365") {
        yearProductList.push(p)
      } else if (p.expiresIn === "30") {
        monthProductList.push(p)
      }
    } else {
      otherProductList.push(p)
    }
  })
  const [products, setProducts] = useState([...monthProductList, ...otherProductList])
  const getCartResult = useQuery(GET_CART, {
    fetchPolicy: "network-only",   // Used for first execution
    nextFetchPolicy: "cache-first",// Used for subsequent executions
    onCompleted: () => {
      setTotalQty(getCartResult.data.cart.contents.itemCount)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  });
  const changeProductQty = (item, e) => {
    const numvalue = e.target.value;
    if (numvalue <= 0) return;
    item.productQty = numvalue
  }


  //add to cart function
  const addTocart = (item) => {
    if (isUserLoggedIn()) {
      setProductItem({
        productId: item.databaseId,
        quantity: Number(item.productQty),
      })
      setTimeout(() => {
        addToCartFun()
      }, 100)
      //item.isLoading = !item.isLoading
      setLoading(item.isLoading = true)
      setTimeout(() => {
        setLoading(item.isLoading = false)
      }, 1000)
    }
    else {
      toLogin()
    }
  }
  const [
    addToCartFun,
    { cartData: addToCartRes, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(ADD_TO_CART, {
    variables: {
      input: productItem,
    },
    onCompleted: (cartData) => {
      notify()
      setTotalQty(cartData.addToCart.cart.contents.itemCount)
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: GET_CART }],
  });

  useEffect(() => {
    console.log(monthPrice, monthProductList, yearProductList, otherProductList)
    setProducts([...monthPrice ? monthProductList : yearProductList, ...otherProductList])
  }, [monthPrice])
  const changePriceMethod = () => {
    setMonthPrice(!monthPrice)
  }
  if(checkStartUrl()){
    return <div></div>
  }
  return (
    <div>
      <HeardTopWrapper title={t('browseOurProducts')} />
      <Layout>
        <Helmet>
          <title>{t('myKitchenInspector')}｜{t('productList')}</title>
        </Helmet>
        <PageContainer>
          <>
            <div className="topBreadscrum">
              <div className="topBreadscrumLeft">{t('productList')} </div>
              <CartIcon totalQty={totalQty} />
            </div>
            <div className="pricing-title justify-content-start margin-bottom">{t('billMonthly')}<input type="checkbox" className="switch" onChange={changePriceMethod} />{t('billAnnually')} </div>
            {
              products.length ? <Row>
                {products.map((item, index) => {
                  return <Col md={6} lg={4} key={item.slug} className="blog-list">

                    <div className="blog-list-item">
                      <Link to={`../shop/${item.slug}`}>
                        <div className="blog-img-wrapper">
                          <Image src={`${item.image ? item.image.sourceUrl : BlogDefaultImage}`} rounded />
                        </div>
                        <h4>{item.name}</h4>

                        <div className="blogDes" dangerouslySetInnerHTML={{ __html: item.shortDescription }}>
                        </div>
                      </Link>
                      <div className="addNumber">
                        <input className="form-control" type="number" min="1" onChange={changeProductQty.bind(this, item)} defaultValue={item.productQty ? item.productQty : 1} />
                        <Button bsstyle="info" className="addButton" disabled={item.stockStatus === "OUT_OF_STOCK"} onClick={!item.isLoading ? addTocart.bind(this, item) : null}>{item.isLoading ? t('adding') : t('addToCart')}</Button>
                        <span className={item.stockStatus === "OUT_OF_STOCK" ? "error" : ""}>{item.stockStatus === "OUT_OF_STOCK" ? "Out of stock" : ""}</span>
                      </div>

                    </div>

                  </Col>
                })}
              </Row>
                : <h2 className="text-center">{t('noItemInTheShop')} </h2>
            }

          </>
        </PageContainer>
      </Layout>

      <ToastContainer />
    </div>


  )
}
export const query = graphql`
  query ($language: String!) {
    allWpSimpleProduct {
      nodes {
        id
        expiresIn
        databaseId
        slug
        sku
        name
        price
        galleryImages {
          nodes {
            link
          }
        }
        image{
          sourceUrl
          title
        }
        shortDescription
        description
        stockStatus
        attributes {
          nodes{ 
            name
            options
          }
       }
        productCategories {
          nodes {
            name
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Shop